import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import { Layout } from 'layout';
import Button from 'common/Button';
import { VisualType, VisualVariant } from 'common/Button/enums';
import ColorTopBar from 'components/ColorTopBar';

import { NotFoundQueryResult } from './model';

import './404.scss';

const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allNotFoundPage: { nodes },
    pageNotFound,
  }: NotFoundQueryResult = useStaticQuery(graphql`
    {
      allNotFoundPage {
        nodes {
          refId
          urls {
            ...UrlsFragment
          }
          seo {
            ...SEOStructureFragment
          }
          text
          lang
          redirectButton {
            ...LinkFragment
          }
          secondRedirectTitle
          secondRedirectButton {
            ...LinkFragment
          }
        }
      }
    }
  `);

  const {
    text,
    redirectButton: { name: redirectButtonName, url: redirectButtonUrl },
    secondRedirectTitle,
    secondRedirectButton: { name: secondRedirectButtonName, url: secondRedirectButtonUrl },
    seo,
    urls,
  } = nodes.find((item) => item.lang === currentLangCode) || pageNotFound;

  return (
    <Layout seo={seo} urls={urls}>
      <ColorTopBar />
      <div className="page-not-found-content">
        <h1 className="page-not-found-content__text">{text}</h1>
        <Link to={redirectButtonUrl} key={redirectButtonUrl}>
          <Button
            ariaLabel={redirectButtonName}
            visualType={VisualType.Rounded}
            visualVariant={VisualVariant.Pink}
          >
            {redirectButtonName}
          </Button>
        </Link>
        <h2 className="page-not-found-content__second-title">{secondRedirectTitle}</h2>
        <Link to={secondRedirectButtonUrl} key={secondRedirectButtonUrl}>
          <Button
            ariaLabel={secondRedirectButtonName}
            visualType={VisualType.Rounded}
            visualVariant={VisualVariant.Pink}
          >
            {secondRedirectButtonName}
          </Button>
        </Link>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
